import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Abonnement og betaling" />
    <div className="longtext">
      <h2>Abonnement og betaling</h2>
      <ul>
        <li>
          <Link to="/help/payment-details">
            Abonnement, betaling og prøveversjon
          </Link>
        </li>
        <li>
          <Link to="/help/cancel-subscription">Avslutte abonnement</Link>
        </li>
        <li>
          <Link to="/help/payment-error-store">Problemer ved kjøp</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
